<template>
  <div class="container" :class="{ 'mobile': type == 'xs' }">
    <div class="right">
      <div class="flinker">
        <img alt="Vue logo" src="./assets/flinker.png" />
      </div>
    </div>
    <div class="left">
      <div
        class="error"
        v-if="error"
      >Er is een fout opgetreden bij het laden van deze pagina : {{ error }}</div>
      <div v-if="session.userId">
        <AFAS_View @raise-error="raiseError" v-cloak></AFAS_View>
      </div>
    </div>
  </div>
</template>

<script>
import AFAS_View from '@/components/View.vue'
import { ref, onMounted } from "vue"
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css';

export default {
  name: 'App',
  components: {
    AFAS_View
  },
  setup() {
    const session = ref({})
    const error = ref(null)
    const authURL = ref(null)

    const raiseError = (err) => {
      error.value = err
    }

    const init = () => {
      return new Promise((resolve, reject) => {

        const params = new URLSearchParams(window.location.search)
        if (!params.get('init_authurl')) {
          const error = new Error("no parameter init_authurl provided in url");
          throw error;
        }

        try {
          authURL.value = params.get('init_authurl') ? params.get('init_authurl') : ""
          resolve()
        } catch (e) {
          reject(e)
        }
      }).catch(err => {
        error.value = err;
        // In case a custom JSON error response was provided          
        if (err.json) {
          return err.json.then(json => {
            // set the JSON response message
            error.value.message = json.message;
          });
        }
      })
    }

    const appendJavascript = (data) => {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = data.scriptUrl;

      document.head.appendChild(script);
    }

    const appendCss = (data) => {
      var cssId = 'afasCss';

      if (!document.getElementById(cssId)) {
        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.id = cssId;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = data.cssUrl;
        link.media = 'all';
        head.appendChild(link);
      }
    }

    const fetchData = () => {
      Nprogress.start()
      // I prefer to use fetch
      // you can use use axios as an alternative
      return fetch(`${authURL.value}${window.location.search}`, {
        method: 'get',
        headers: {
          'content-type': 'application/json'
        }
      })
        .then(res => {
          // a non-200 response code
          if (!res.ok) {
            // create error instance with HTTP status text            
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then(json => {
          // set the response data
          Object.assign(session.value, json);

          //append css and js-file               
          appendJavascript(session.value)
          appendCss(session.value)
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided          
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          Nprogress.done()
        })
    }

    onMounted(async () => {
      await init()
      if (!error.value) {
        await fetchData()
      }

    })


    return { session, error, raiseError }
  }
}
</script>

<style scoped>
.username {
  text-align: right;
  margin-right: 10px;
}
.container {
  height: auto;
  overflow: hidden;
}

.right {
  width: 20px;
  float: right;
}

.left {
  float: none;
  width: auto;
  overflow: hidden;
}

.flinker {
  padding-left: 2px;
  padding-top: 2px;
  opacity: 0.3;
}

.error {
  padding: 2em;
  color: #d90000;
}
</style>
