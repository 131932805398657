<template>
    <div class="part pagetile clear" style="visibility: visible;">
        <div class="content" v-if="rows">
            <div class="webview desktop">
                <div class="viewdata normal selectable">
                    <div class="scroll">
                        <div class="grid noWrap">
                            <div class="head">
                                <!-- header -->
                                <div class="headerRow">
                                    <!-- headerColumn -->
                                    <div
                                        v-for="field in metaData.fields"
                                        :key="field"
                                        class="headerColumn"
                                        :style="{ width: field.width }"
                                    >
                                        <div class="headerLabel">{{ field.label }}</div>
                                        <div class="resize"></div>
                                    </div>
                                </div>
                                <!-- filter -->
                                <div class="filterRow">
                                    <div
                                        v-for="field in metaData.fields"
                                        :key="field"
                                        class="filterColumn"
                                    >
                                        <div class="filter">
                                            <input
                                                type="text"
                                                v-model="field.filter"
                                                v-on:keyup.enter="filter"
                                                class="value"
                                                style="width: 100%"                    
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- body -->
                            <div class="body">
                                <div v-if="rows.length == 0" class="dataRow fillOut">
                                    <span class="noDataWithFilter">Geen resultaten.</span>
                                </div>
                                <!-- row -->
                                <AFAS_Row
                                    v-for="(row, index) in rows"
                                    :key="row"
                                    :row="row"
                                    :rowIndex="index"
                                    :metaData="metaData"
                                ></AFAS_Row>
                            </div>
                        </div>
                    </div>

                    <div class="navigation">
                        <div class="bar">
                            <button
                                @click="pagination(-1)"
                                v-if="paginationState"
                                class="webbutton webbutton-text-only webbutton-color-normal cursorpointer"
                            >
                                <span class="webbutton-text">Vorige</span>
                            </button>
                            <button
                                v-if="rows.length >= takeCount"
                                @click="pagination(1)"
                                class="webbutton webbutton-text-only webbutton-color-normal cursorpointer"
                            >
                                <span class="webbutton-text">Volgende</span>
                            </button>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AFAS_Row from '@/components/Row.vue'
import { ref, onMounted } from "vue"
import Nprogress from 'nprogress'

export default {
    name: 'AFAS_View',
    components: {
        AFAS_Row
    },
    emits: ['raise-error'],
    setup(props, { emit }) {

        const paginationState = ref(0)
        const error = ref(null)
        const rows = ref(null)
        const metaData = ref(null)
        const skip = ref(0)
        const take = ref(20)
        const takeCount = ref(20)
        const filterValue = ref(null)
        let dataURL = ""


        const init = () => {

            return new Promise((resolve, reject) => {
                const params = new URLSearchParams(window.location.search)

                if (!params.get('init_dataurl')) {
                    const error = new Error("no parameter init_dataurl provided in url");
                    throw error;
                }

                try {
                    take.value = parseInt(params.get('init_take') ? params.get('init_take') : 20)
                    takeCount.value = take.value
                    dataURL = params.get('init_dataurl') ? params.get('init_dataurl') : ""
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(err => {
                error.value = err;
                emit('raise-error', error.value)
                // In case a custom JSON error response was provided          
                if (err.json) {
                    return err.json.then(json => {
                        // set the JSON response message
                        error.value.message = json.message;
                    });
                }
            })
        }


        const pagination = (next) => {
            paginationState.value = paginationState.value + next
            skip.value = (skip.value + (next * take.value)) < 0 ? 0 : (skip.value + (next * take.value))
            fetchData()
        }

        const filter = () => {
            buildFilter()
            .then(fetchData)
        }

        const buildFilter = () => {
            return new Promise((resolve, reject) => {

                try {
                    let filterString = {
                        "filterfieldids": "",
                        "filtervalues": "",
                        "operatortypes": ""
                    }

                    if (metaData.value) {
                        metaData.value.fields.map(field => {
                            if (field.filter) {
                                filterString.filterfieldids = filterString.filterfieldids ? filterString.filterfieldids + `,${field.id}` : `${field.id}`
                                filterString.filtervalues = filterString.filtervalues ? filterString.filtervalues + `,${field.filter}` : `${field.filter}`
                                filterString.operatortypes = filterString.operatortypes ? filterString.operatortypes + ',15' : '15'
                            }
                        })
                    }

                    filterValue.value = filterString.filterfieldids ? encodeURIComponent(JSON.stringify(filterString)) : ""
                    skip.value = 0
                    take.value = takeCount.value

                    resolve()
                } catch (e) {
                    reject(e)
                }
            })
        }



        const calculateColumnWidth = () => {
            const totallength = metaData.value.fields.reduce((previousValue, field) => {
                return previousValue + field.length
            }, 0)

            metaData.value.fields = metaData.value.fields.reduce((previousValue, field,) => {
                field.width = (field.length / totallength * 100) < 10 ? "10%" : (field.length / totallength * 100) + "%"
                field.filter = "" //add filter value
                previousValue.push(field)
                return previousValue
            }, [])
        }

        const fetchData = () => {
            Nprogress.start()

            return fetch(`${dataURL}${window.location.search}&skip=${skip.value}&take=${take.value}&filter=${filterValue.value}`, {
                method: 'get',
                headers: {
                    'content-type': 'application/json'
                }
            })
                .then(res => {
                    // a non-200 response code
                    if (!res.ok) {
                        // create error instance with HTTP status text            
                        const error = new Error(res.statusText);
                        error.json = res.json();
                        throw error;
                    }
                    return res.json();
                })
                .then(json => {
                    // set the response data
                    rows.value = json.rows;

                    //do not replace metadata if exists
                    if (!metaData.value) {
                        metaData.value = json.metaData;
                        calculateColumnWidth(json.metaData)
                    }
                })
                .catch(err => {
                    error.value = err;                   
                    // In case a custom JSON error response was provided          
                    if (err.json) {
                        return err.json.then(json => {
                            // set the JSON response message
                            error.value.message = json.message;
                        });
                    }
                    emit('raise-error', error.value)
                })
                .then(() => {
                    Nprogress.done()
                })
        }

        onMounted(async () => {
            await init()
            if (!error.value) {
                await buildFilter()
                await fetchData()
            }
        })

        return {
            rows,
            metaData,
            error,
            pagination,
            paginationState,
            takeCount,            
            filter
        }

    }
}

</script>

<style scoped>
.part {
    /* padding-left: 1em; */
    padding-right: 1em;
}
</style>